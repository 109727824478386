<template>
    <v-container v-if="!loading" class="pa-0">
        <a-alert v-for="(error, i) in errors" :key="i" :message="error" />
        <slot v-if="!errors.length"></slot>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { ValidationProvider } from 'vee-validate';

import { AAlert } from '@/components/AAlert';

type AmpValidationInput = Vue & {
    $refs: {
        provider: InstanceType<typeof ValidationProvider>;
    };
};

const AmpValidationProps = Vue.extend({
    name: 'AmpValidation',
    props: {
        input: {
            type: Object as PropType<AmpValidationInput>,
            default() {
                return null;
            }
        },
        parse: {
            type: Boolean,
            default() {
                return false;
            }
        },
        limit: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AAlert
    }
})
export default class AmpValidation extends AmpValidationProps {
    get errors() {
        const errors = (this.input as AmpValidationInput)?.$refs?.provider
            ?.errors;

        if (errors) {
            const toDisplay = this.parse
                ? errors.flatMap(error => JSON.parse(error))
                : errors;

            if (this.limitBy) {
                return toDisplay.slice(0, this.limitBy);
            }

            return toDisplay;
        }

        return [];
    }

    get limitBy() {
        return Number(this.limit);
    }

    @Watch('input')
    onInputUpdated() {
        const input = this.input as AmpValidationInput;

        if (input?.$refs.provider) {
            input.$refs.provider.validate();
        }
    }
}
</script>
