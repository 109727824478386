<template>
    <v-row>
        <v-col cols="12" md="8" :class="lastColumnLeft">
            <v-container :class="lastInput">
                <slot name="input"></slot>
            </v-container>
        </v-col>
        <v-col cols="12" md="4" :class="lastColumnRight">
            <v-container :class="lastValidation">
                <slot name="validation"></slot>
            </v-container>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import AmpPageMixin from './AmpPageMixin';

@Component
export default class AmpRowLast extends mixins(AmpPageMixin) {}
</script>
