<template>
    <v-row>
        <v-col cols="12" md="8" :class="firstColumnLeft">
            <v-container :class="firstInput">
                <slot name="input"></slot>
            </v-container>
        </v-col>
        <v-col cols="12" md="4" :class="firstColumnRight">
            <v-container :class="firstValidation">
                <slot name="validation"></slot>
            </v-container>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import AmpPageMixin from './AmpPageMixin';

@Component
export default class AmpRowFirst extends mixins(AmpPageMixin) {}
</script>
