import Vue from 'vue';
import Component from 'vue-class-component';

import './AmpPage.scss';

@Component
export default class AmpPageMixin extends Vue {
    get input() {
        return [this.inputs, this.inputPadding, 'py-0', 'elevated-input'].join(
            ' '
        );
    }

    get inputs() {
        return 'white';
    }

    get inputPadding() {
        return this.$vuetify.breakpoint.smAndDown ? 'pa-4' : 'pa-6';
    }

    get firstInput() {
        return [
            this.inputs,
            this.inputPadding,
            'pb-0',
            'elevated-input-first'
        ].join(' ');
    }

    get lastInput() {
        return [
            this.inputs,
            this.inputPadding,
            'pt-0',
            this.$vuetify.breakpoint.smAndDown
                ? 'elevated-input'
                : 'elevated-input-last'
        ].join(' ');
    }

    get columnLeft() {
        return 'py-0';
    }

    get columnRight() {
        return 'py-0';
    }

    get firstColumnLeft() {
        return 'pb-0';
    }

    get lastColumnLeft() {
        return this.$vuetify.breakpoint.smAndDown ? 'py-0' : 'pt-0';
    }

    get firstColumnRight() {
        return this.$vuetify.breakpoint.smAndDown ? 'py-0' : '';
    }

    get lastColumnRight() {
        return this.$vuetify.breakpoint.smAndDown ? 'pt-0' : 'py-0';
    }

    get validation() {
        return this.$vuetify.breakpoint.smAndDown
            ? 'white px-4 py-0 elevated-input'
            : 'py-0 px-0';
    }

    get firstValidation() {
        return this.$vuetify.breakpoint.smAndDown
            ? 'white px-4 py-0 elevated-input'
            : 'pt-6 pb-0 px-0';
    }

    get lastValidation() {
        return this.$vuetify.breakpoint.smAndDown
            ? 'white px-4 pt-0 elevated-input-last'
            : 'py-0 px-0';
    }
}
